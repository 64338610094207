import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { Header } from 'components';
import { Layout, Container } from 'layouts';
import styled from '@emotion/styled';
const Fspace = styled.div`
//display: flex;
height:1rem;
background: white;
min-height:20rem;
@media (max-width: 800px) {
    // padding-top: 10%;
    // padding-bottom: 5%;
  }
`;
const ContainerWrapper = styled.div`
position: relative;
display: grid;
top:-5rem;
overflow:hidden;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: 
  "a b c"
  ;
  grid-auto-flow: dense; 
  //background: #FFF4F2;
padding: 0 5rem;
@media (max-width: 800px) {
  grid-template-areas: 
  "a" 
  "b"
   "c"
  ;
  margin:auto;
  padding: 0 1rem;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  }
`;
const Support = styled.div`
grid-area: a;
padding: 2rem;
display:flex;
flex-direction:column;
text-align:left;

@media (max-width: 800px) {

  }
`;
const Feedback = styled.div`
grid-area: b;
padding: 2rem;
text-align:left;
display:flex;
flex-direction:column;

@media (max-width: 800px) {

  }
`;
const News = styled.div`
grid-area: c;
text-align:left;
padding: 2rem;
display:flex;
flex-direction:column;

@media (max-width: 800px) {

  }
`;
const LeftText = styled.div`
text-align:left;
@media (max-width: 800px) {

  }
`;
const About = center => (
  <Layout>
    <Helmet title={'About Page'} />
    <Header title="Info">Limitless Unity Development</Header>
    <Container center={center}>
      {/* <h3> */}
      <ContainerWrapper>
        <Support>
          <LeftText><h1>Support</h1></LeftText>
          <LeftText>If you run into any issues or have questions, please do not hesitate to reach out ! I will do my best to respond as soon as I can.</LeftText>
          <br/>
           <a href="">Contact email: limitlessunitydevelopment@gmail.com</a>
           <a href="https://assetstore.unity.com/publishers/39817">Unity forum profile.</a>
          <br/>
          <LeftText>Please keep in mind that I’m in the UTC+5 timezone and usually reserve weekends for personal time</LeftText>
    
      </Support>
        <Feedback>
        <LeftText><h1>
            Feedback
          </h1> </LeftText>

          <LeftText>If you want to leave a feedback, ask for new feature or share your WIP, please use Asset related forum thread.</LeftText>
          <br/>
           <a href="https://forum.unity.com/threads/released-retro-look-pro-post-processing-effects.583285/">Retro Look Pro.</a>
           <a href="https://forum.unity.com/threads/released-limitless-glitch-effects.726563/">Limitless Glitch Effects.</a>
           {/* <a href="https://limitless-development.itch.io/retro-look">Retro Look .</a>
           <a href="https://limitless-development.itch.io/limitless-gravity-2d">Gravity 2D</a> */}
           
           {/* <a href="https://assetstore.unity.com/publishers/39817">Reflect Pro</a> */}

        </Feedback>
        <News>
        <LeftText><h1>News</h1></LeftText>
        <LeftText>Get latest news and be up to date</LeftText>
        <br/>
           <a href="https://twitter.com/DevLimitless">Twitter</a>
           <a href="https://limitless-development.itch.io">Itch.io Link.</a>
        </News>
      </ContainerWrapper>

    </Container>

  </Layout>
);

export default About;

Container.propTypes = {
  center: PropTypes.object,
};
